import request from '@/utils/request'

export function getInFo(data) {
    return request({
        url: ''
    })
}

//查询标签分类
export function getLabelClassList(data) {
    return request({
        url: '/system/header/list',
        method: 'get',
        params: data
    })
}


//获取标签列表
export function getLabelList(data) {
    return request({
        url: '/system/types/list',
        method: 'get',
        params: data
    })
}


// 查询试题库列表
export function listBank(data) {


    return request({
        url: '/system/bank/list',
        method: 'post',
        data: data
    })
}

//精准查询题库列表
export function getPreciseBankList(data) {
    return request({
        url: '/system/bank/list2',
        method: 'post',
        data: data
    })
}

//查询题目详情
export function getBankDetail(data) {
    return request(({
        url: '/system/bank/' + data,
        method: 'get',

    }))
}

// 查询试题库分类
export function listBankClass(query) {
    return request({
        url: '/system/classification/list',
        method: 'get',
        params: query
    })
}


//查询试卷分类
export function getTestPaperClass(query) {
    return request({
        url: '/system/testPaper/classification/list',
        method: 'get',
        params: query
    })
}

//查询试卷列表
export function getTestPaperList(query) {
    return request({
        url: 'system/summary/list',
        method: 'get',
        params: query
    })
}

//登录
export function login(data) {
    return request({
        url: 'login',
        method: 'post',
        data
    })
}

//获取用户信息
export function getUserInfo() {
    return request({
        url: 'getInfo',
        method: 'get'
    })
}


//查询试卷详情
export function getExamDetail(data) {
    return request({
        url: '/system/summary/' + data,
        method: 'get'
    })
}


//导出试卷
export function exportExamPaper(data) {
    return request({
        url: '/system/summary/export/' + data,
        method: 'post',
        timeout: Infinity,
        headers: {'Content-Type': 'application/x-www-form-urlencoded'},
        responseType: 'blob',
    })
}


//学生注册
export function addStudentUser(data) {
    return request({
        url: '/system/sysuser1',
        method: 'post',
        data
    })
}

//修改用户信息
export function updataStudentUser(data) {
    return request({
        url: '/system/sysuser1',
        method: 'put',
        data
    })
}

//验证班级编号
export function verifyCode(data) {
    return request({
        url: '/system/code/code/' + data,
        method: 'get'
    })
}

//删除班级邀请码
export function deletVerifyCode(data) {
    return request({
        url: '/system/code/delete/' + data,
        method: 'delete'
    })
}


//学生信息
export function getProfile(data) {
    return request({
        url: '/system/sysuser1/' + data,
        method: 'get'
    })
}

//试卷下载记录
export function getDownloadRecordList(data) {
    return request({
        url: '/system/download/record/list',
        method: 'get'
    })
}


//获取阅卷列表
export function getMarkingList(data) {
    return request({
        url: '/system/markPapers/paper/list',
        method: 'get',
        params: data
    })
}

//获取阅卷详情
export function getMarkingDetail(data) {
    return request({
        url: '/system/markPapers/paper/' + data,
        method: 'get'
    })
}


// 查询已阅卷的答疑区
export function getPaperMarkingQA(jobTestPaperId) {
    return request({
        url: '/system/reply/list',
        method: 'get',
        params: {
            jobTestPaperId: jobTestPaperId,
            pageSize: 9999

        }
    })
}

// 新增已阅卷的答疑区评论
export function addPaperMarkingQA(data) {
    return request({
        url: '/system/reply',
        method: 'post',
        data: data
    })
}


// 上传图片
export function upLoad(data) {
    return request({
        url: '/common/upload',
        method: 'post',
        data: data,
    })
}


//查询学情分析记录
export function getStudyList(data) {
    return request({
        url: '/system/studentAnalysis/paper/list',
        method: 'get',
        params: data
    })
}

//加入错题卷2
export function addErrorBank2(data) {
    return request({
        url: '/system/studentAnalysis/statistics/add2',
        method: 'post',
        data
    })
}

//加入错题卷1
export function addErrorBank(data) {
    return request({
        url: '/system/studentAnalysis/statistics',
        method: 'post',
        data
    })
}
//修改错题卷1
export function editErrorBank(data) {
    return request({
        url: '/system/studentAnalysis/paper',
        method: 'put',
        data
    })
}

//查询错题详情
export function getErrorBankDetail(data) {
    return request({
        url: "/system/studentAnalysis/paper/" + data,
        method: 'get'
    })
}

//学情分析 分析统计
export function analysisAndStatistics(data) {
    return request({
        url: '/system/viewLearningStatisticsReport/list',
        method: 'get',
        params: data
    })
}

//学情分析 标签统计
export function analysisAndStatistics2(data) {

    console.log('data', data)
    return request({
        url: `/system/viewLearningStatisticsReport/list2`,
        // url: `/system/viewLearningStatisticsReport/list2?userId=${data.userId}&params.beginCreateTime=${data.params && data.params.beginCreateTime}&params.endCreateTime=${data.params && data.params.endCreateTime}`,
        // url:'/system/viewLearningStatisticsReport/list2?userId='+data.userId+'&params.beginCreateTime='+data.params&&data.params.beginCreateTime+'&params.endCreateTime='+data.params&&data.params.endCreateTime,
        method: 'get',
        params:data
    })
}


//提出疑问
export function raiseQuestions(data) {
    return request({
        url: '/system/markPapers/paper',
        method: 'put',
        data: data
    })
}

//获取消息列表
export function getMessageList(data) {
    return request({
        url: '/system/information/information/list',
        method: 'get',
        params: data
    })
}


// 用户密码重置
export function updateUserPwd(oldPassword, newPassword) {
    const data = {
        oldPassword,
        newPassword
    }
    return request({
        url: '/system/user/profile/updatePwd',
        method: 'put',
        params: data
    })
}



//试题录入
export function addMarking(data) {
    return request({
        url: '/system/markPapers/paper',
        method: 'post',
        data: data
    })
}


//根据用户id查看班级
export function getStudentClassList(data){
    return request({
        url:'/system/classUser/list',
        method:'get',
        params:data
    })
}

<template>
  <div class="sidebarBox">
    <div class="sidebarBox-content">
      <div :class="{sidebarBoxHover:routerName=='questionBank'}" @click="goRouter('questionBank')">题库大厅</div>
      <div :class="{sidebarBoxHover:routerName=='examPaperLibrary'}" @click="goRouter('examPaperLibrary')">试卷库</div>
      <div :class="{sidebarBoxHover:routerName=='forum'}" @click="goRouter('forum')">论坛</div>
      <div :class="{sidebarBoxHover:routerName=='analysisOfStudents'}" @click="goRouter('analysisOfStudents')">学情分析</div>
      <div :class="{sidebarBoxHover:routerName=='personalProfile'}" @click="goRouter('personalProfile')">个人档案</div>

    </div>
  </div>
</template>
<script>

import {mapGetters} from "vuex";

export default {
  name: "sidebar",
  watch: {
    $route: {
      handler: function (n) {
        this.routerName = n.name
      },

      deep: true,
      immediate: true
    }
  },
  computed:{
    ...mapGetters(['isLogin'])
  }
  ,
  data() {
    return {
      routerName: ''
    }
  },
  methods: {
    goRouter(path) {

      if(this.routerName==path)return
      console.log('点击跳转',path)
      if(path=='analysisOfStudents'||path=='personalProfile'||path=='examPaperLibrary'){
        if(!this.isLogin){

          this.$router.push('/login')
          return;
        }

      }


      this.$router.replace(path)
      // this.$router.push(path)
    }
  }
}
</script>

<style lang="scss" scoped>
.sidebarBox {

  background-image: url("@/assets/backgroundNav.png");
  background-size: 100% 100%;

  &-content {
    height: 60px;
    width: 70%;
    margin: 0 auto;
    display: flex;
    align-items: center;

    > div {
      margin-left: 30px;
      font-size: 16px;
      cursor: pointer;
    }
  }

  .sidebarBoxHover {
    color: #008593;
    font-weight: bold;
  }
}
</style>
